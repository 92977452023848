


















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {IAnalysisTopProduct} from '@/types/IAnalysisTopProduct';

@Component({
  name: 'DashboardTopProducts'
})

export default class DashboardTopProducts extends Vue {
  @Prop()
  products!: {[key: string]: IAnalysisTopProduct[]}

  sortBy: string = 'qty'
  sortDesc: boolean = true

  headers: { text: string, sortable?: boolean, value: string }[] = [
    {
      text: 'SYSTEM_SKU',
      value: 'sku'
    }, {
      text: 'SYSTEM_QUANTITY',
      value: 'qty'
    }, {
      text: 'SYSTEM_NETTO',
      value: 'netEur'
    }, {
      text: 'SYSTEM_TAX',
      value: 'taxEur'
    }, {
      text: 'SYSTEM_GROSS',
      value: 'grossEur'
    }
  ]
  tab: number = 0;
}
