import axios from 'axios';
import IDashboardFilter from '@/types/IDashboardFilter';

export default class DashboardRepository {

  static getMarketplacePieData(filters: IDashboardFilter) {
    return axios.post(`analysis/dashboard/marketplace/pie`, filters);
  }

  static getMarketplaceLineData(filters: IDashboardFilter) {
    return axios.post(`analysis/dashboard/marketplace/line`, filters);
  }

  static getSkuData(filters: IDashboardFilter) {
    return axios.post(`analysis/dashboard/sku`, filters);
  }

  static getTopProducts(filters: IDashboardFilter) {
    return axios.post('analysis/dashboard/top-products', filters)
  }

}
