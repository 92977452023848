




















































































import {Component, Vue} from 'vue-property-decorator';
import DashboardService from '@/services/DashboardService';
import IDashboardFilter from '@/types/IDashboardFilter';
import {IDashboardAnalysis} from '@/types/IDashboardTypes';
import {dateToYYYYmmDD, startMonthDate, subtractMonths} from '@/utils/dateTime';
import {resolveError} from '@/utils/notifications';
import {isPermission} from '@/utils/perrmissions';
import DashboardAnalysisComponent from '@/components/Dashboard/AnalysisComponent.vue';
import MultiLineChart from '@/components/Dashboard/MultiLineChart.vue';
import {IAnalysisMarketplace} from '@/types/IAnalysisMarketplace';
import colors from '@/constants/Colors';
import ConfigurationFinishModal from '@/components/ConfigurationFinishModal.vue';
import {getSavedPageData, savePageData} from '@/utils/pageDataSaver';
import {IAnalysisTopProduct} from '@/types/IAnalysisTopProduct';
import DashboardTopProducts from '@/components/Dashboard/DashboardTopProducts.vue';

@Component({
  name: 'Dashboard',
  components: {
    DashboardAnalysisFilters: () => import('@/components/Dashboard/DashboardAnalysisFilters.vue'),
    DashboardAnalysisTable: () => import('@/components/Dashboard/DashboardAnalysisTable.vue'),
    MultiLineChart,
    ConfigurationFinishModal,
    DashboardAnalysisComponent,
    DashboardTopProducts
  },
})
export default class Dashboard extends Vue {
  marketplaceFilters: IDashboardFilter = {
    dateFrom: startMonthDate(subtractMonths(new Date(), 1)),
    dateTo: dateToYYYYmmDD(this.lastDayOfRange()),
    keys: [],
  };

  marketplacesList: IAnalysisMarketplace[] = [];

  marketplacePieDataLoading: boolean = false;
  marketplaceLineDataLoading: boolean = false;

  marketplacePieData: {
    data: IDashboardAnalysis[]
    series: number[],
    labels: string[],
    colors: string[]
  } = {
    data: [],
    series: [],
    labels: [],
    colors: []
  };

  marketplaceLineData: {
    series: { color: string, data: object, name: string }[],
    labels: string[]
  } = {
    series: [],
    labels: [],
  };

  topProducts: { [key: string]: IAnalysisTopProduct[] } = {}

  isOpen: boolean = true;

  closeModal() {
    this.isOpen = false;
    this.$router.replace(this.$route.path)
  }

  lastDayOfRange() {
    const dateUTC = new Date().getTime()
    const todayDataUpdateAt = new Date().setUTCHours(14, 0, 0)

    const prevDay = new Date()
    prevDay.setUTCHours(0, 0, 0)

    if (dateUTC > todayDataUpdateAt) {
      prevDay.setDate(new Date().getDate() - 1)
      return prevDay
    }

    prevDay.setDate(new Date().getDate() - 2)
    return prevDay
  }

  async created() {
    this.getSavedFilters()
    if (this.isPermission(['dashboard.view'])) {
      await this.fetchFiltersData();
    }
  }

  getSavedFilters() {
    const {filters} = getSavedPageData()
    if (filters) {
      this.marketplaceFilters = filters
    }
  }

  async onFiltersChanged() {
    savePageData({
      filters: this.marketplaceFilters
    })
    await this.fetchData()
  }

  fetchData() {
    this.fetchLineMarketplaceData();
    this.fetchPieMarketplaceData();
    this.fetchTopProducts()
  }

  async fetchTopProducts() {
    try {
      this.topProducts = await DashboardService.getTopProducts(this.marketplaceFilters)
    } catch (e) {
      resolveError(e, 'fetch_top_products');
    }
  }

  async fetchFiltersData() {
    try {
      const {
        marketplacesList,
      } = await DashboardService.getFiltersData();
      this.marketplacesList = marketplacesList.map((item, idx) => ({...item, color: colors[idx]}));
      if (!this.marketplaceFilters.keys.length) {
        this.marketplaceFilters.keys = marketplacesList.map(m => m.name);
      }
      await this.fetchData();
    } catch (e) {
      resolveError(e, 'fetch_filters');
    }
  }

  getColorByMarketplaceName(name: string) {
    const candidate = this.marketplacesList.find(item => item.name === name)

    return candidate?.color || '#EC407A'
  }

  async fetchPieMarketplaceData() {
    try {
      this.marketplacePieDataLoading = true;
      const filters: IDashboardFilter = {
        dateFrom: this.marketplaceFilters.dateFrom,
        dateTo: this.marketplaceFilters.dateTo,
        keys: []
      };
      const {
        data,
        labels,
        series,
      } = await DashboardService.getMarketplaceDataPie(filters);
      const labelsColors: string[] = []
      labels.forEach(el => {
        const obj = this.marketplacesList.find(data => data.name === el)
        // @ts-ignore
        labelsColors.push(obj.color)
      })
      this.marketplacePieData.data = data;
      this.marketplacePieData.labels = labels;
      this.marketplacePieData.series = series
      this.marketplacePieData.colors = labelsColors
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.marketplacePieDataLoading = false;
    }
  }

  async fetchLineMarketplaceData() {
    try {
      this.marketplaceLineDataLoading = true;
      const data = await DashboardService.getMarketplaceDataLine(this.marketplaceFilters);
      this.marketplaceLineData.series = data.series.map(item => ({
        ...item,
        color: this.getColorByMarketplaceName(item.name)
      }));
      this.marketplaceLineData.labels = data.labels;
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.marketplaceLineDataLoading = false;
    }
  }

  isPermission(permissions: string[]): boolean {
    return isPermission(permissions);
  }

  get QueryParams() {
    return this.$route.query;
  }

}
