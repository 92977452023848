












import {Component, Vue} from 'vue-property-decorator';
import Navigation from '../components/Navigation.vue';
import Header from '../components/Header.vue';
import {namespace} from 'vuex-class';
import {getLocalStorage, setLocalStorage} from '@/utils/localStorage';

const layout = namespace('layout');
@Component({
  name: 'MainLayout',
  components: {
    Header,
    Navigation,
  },
})
export default class MainLayout extends Vue {
  showBottomNavigation: boolean = false;

  @layout.State
  isMinimized!: boolean;

  @layout.Getter
  isMobile!: boolean;

  get drawer(): boolean {
    if (this.isMobile) {
      return this.showBottomNavigation;
    }
    return this.isMinimized;
  }

  set drawer(isMinimized: boolean) {
    if (this.isMobile) {
      this.showBottomNavigation = isMinimized;
    } else {
      this.$store.commit('layout/setMenuState', isMinimized);
      setLocalStorage('isMenuMinimized', isMinimized);
    }
  }

  mounted() {
    const isMenuMinimized = getLocalStorage('isMenuMinimized');
    this.drawer = !!isMenuMinimized || isMenuMinimized === 'true';
  }
}
