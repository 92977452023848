import axios from 'axios';
import IAnalysisFilters from '@/types/IAnalysisFilters';

export default class AnalysisRepository {
  static get(filters: IAnalysisFilters) {
    return axios.post(`/analysis/chart`, filters);
  }

  static download(fileType: string, filters: IAnalysisFilters) {
    return axios.post(`/analysis/${fileType}`, filters, {responseType: 'blob'});
  }

  static getFilters() {
    return axios.get(`analysis/forms`);
  }
}
