




































































import {Component, Emit, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import AuthService from '@/services/AuthService';
import CompaniesService from '@/services/CompaniesService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import LanguageSelect from '@/components/LanguageSelect.vue';
import PaymentService from '@/services/PaymentService';

const user = namespace('user');

@Component({
  name: 'Header',
  components: {LanguageSelect},
})
export default class Header extends Vue {
  @user.State('isLoggedAs')
  isLoggedAs!: boolean;

  @user.State('companyName')
  companyName!: string;

  @user.State('mail')
  mail!: string;

  logoutLoading: boolean = false;
  loading: boolean = true;
  activeSubscription: any = {};

  @Emit('toggleNavigation')
  toggleNavigation() {
    //
  }

  logout() {
    AuthService.logout();
  }

  @Watch('$route', {
    immediate: true,
    deep: true,
  })
  onUrlChange() {
    this.getActiveSubscription();
  }

  async logoutAs() {
    try {
      this.logoutLoading = true;
      await CompaniesService.logoutAs();
      await this.$router.push({name: 'Home'}).catch(() => {
        //
      });
      notify(NotificationTypes.success, this.$t('SYSTEM_LOGGED_OUT'));
    } catch (e) {
      resolveError(e, 'logout');
    } finally {
      this.logoutLoading = false;
    }
  }

  async getActiveSubscription() {
    try {
      this.activeSubscription = await PaymentService.getActiveSubscription();
    } catch (e) {
      // resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }
}
