import {IAnalysisChartItem} from '@/types/IAnalysisCharts';
import {IAnalysisTopProduct} from '@/types/IAnalysisTopProduct';

export default class AnalysisFactory {
  static toChartData(data: any): IAnalysisChartItem {
    const chartData: IAnalysisChartItem = {};
    Object.keys(data).forEach((dateKey: string) => {
      chartData[dateKey] = {};
      Object.keys(data[dateKey]).forEach((typeKey: string) => {
        chartData[dateKey][typeKey] = data[dateKey][typeKey].map((item: any) => {
          return {
            sku: item.sku,
            value: item.value,
          };
        });
      });
    });
    return chartData;
  }

  static toTopProduct(data: any): IAnalysisTopProduct {
    return {
      qty: data.qty,
      sku: data.sku,
      taxEur: data.taxEur,
      netEur: data.netEur,
      grossEur: data.grossEur,
    }
  }
}
