import IMenuItem from '@/types/IMenuItem';
import ISubMenuItem from '@/types/ISubMenuItem';

const menuItems: (IMenuItem | ISubMenuItem)[] = [
  {
    icon: 'mdi-home',
    text: 'SYSTEM_DASHBOARD',
    routeName: 'Home',
    permissions: ['dashboard.view'],
  },
  {
    icon: 'mdi-cogs',
    text: 'SYSTEM_CONFIGURATION',
    routeName: 'Configuration',
    permissions: ['configuration.view'],
  },
  {
    icon: 'mdi-account-group',
    text: 'SYSTEM_USERS',
    routeName: 'Users',
    permissions: ['admin.user_view'],
  },
  {
    icon: 'mdi-chart-bar',
    text: 'SYSTEM_ANALYSES',
    routeName: 'Analyses',
    permissions: ['analysis.view'],
  },
  {
    icon: 'mdi-file-document',
    text: 'SYSTEM_MARGIN',
    routeName: 'Margin',
    permissions: ['margin.view'],
  },
  {
    icon: 'mdi-cash-usd',
    text: 'SYSTEM_TKW_TITLE',
    routeName: 'Tkw',
    permissions: ['tkw.view'],
  },
  {
    icon: 'mdi-cube-outline',
    text: 'SYSTEM_STOCK',
    routeName: 'Stock',
    permissions: ['stock.view'],
  },
  {
    icon: 'mdi-source-pull',
    text: 'SYSTEM_STOCK_MOVEMENTS',
    routeName: 'StockMovements',
    permissions: ['stock.view'],
  },
  {
    icon: 'mdi-truck',
    text: 'SYSTEM_STOCK_INTERNATIONAL_MOVEMENTS',
    routeName: 'StockInternationalMovements',
    permissions: ['stock.view'],
  },
  {
    icon: 'mdi-file-document',
    text: 'SYSTEM_REPORTS',
    routeName: 'Reports',
    permissions: ['report.view'],
  },
  {
    icon: 'mdi-office-building',
    text: 'SYSTEM_COMPANY',
    routeName: 'Company',
    permissions: ['company.view'],
  },
  {
    icon: 'mdi-city-variant',
    text: 'SYSTEM_COMPANIES',
    routeName: 'Companies',
    permissions: ['admin.company_view'],
  },

  {
    icon: 'mdi-google-translate',
    text: 'SYSTEM_TRANSLATIONS',
    routeName: 'Translations',
    permissions: ['admin.translation'],
  },

  {
    icon: 'mdi-cash-multiple',
    text: 'SYSTEM_REPRICING',
    routeName: 'Repricing',
    permissions: ['repricing.view'],
  },

  // { icon: 'mdi-history', text: 'About', routeName: 'About' },
  // { icon: 'mdi-content-copy', text: 'Duplicates' },
  // {
  //   icon: 'mdi-chevron-up',
  //   'icon-alt': 'mdi-chevron-down',
  //   text: 'Labels',
  //   model: true,
  //   children: [
  //     {
  //       icon: 'mdi-plus',
  //       text: 'Create label',
  //       routeName: 'About'
  //     }
  //   ],
  // },
  // { icon: 'mdi-cog', text: 'Settings' },
  // { icon: 'mdi-message', text: 'Send feedback' },
  // { icon: 'mdi-help-circle', text: 'Help' },
  // { icon: 'mdi-cellphone-link', text: 'App downloads' },
  // { icon: 'mdi-keyboard', text: 'Go to the old version' },
];

export default menuItems;
