export default [
  '#ec6868',
  '#d463e7',
  '#7fbbff',
  '#80CBC4',
  '#a1a8ac',
  '#e1ee65',
  '#ecb158',
  '#65cb6a',
  '#f11f1f',
  '#BCAAA4',
  '#5b6af5'
]
