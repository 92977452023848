






































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'ConfigurationFinishModal'})
export default class ConfigurationFinishModal extends Vue {

  @Prop()
  modal!: boolean;

  closeDialog() {
    this.$emit('close');
  }

};
