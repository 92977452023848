import DashboardRepository from '@/repositories/DashboardRepository';
import IDashboardFilter from '@/types/IDashboardFilter';
import DashboardAnalysesFactory from '@/factories/DashboardAnalysesFactory';
import {IDashboardAnalysis} from '@/types/IDashboardTypes';
import AnalysisRepository from '@/repositories/AnalysisRepository';
import {IAnalysisMarketplace} from '@/types/IAnalysisMarketplace';
import AnalysisFactory from '@/factories/AnalysisFactory';
import {IAnalysisTopProduct} from '@/types/IAnalysisTopProduct';

export default class DashboardService {
    static async getMarketplaceDataPie(filters: IDashboardFilter)
        : Promise<{ data: IDashboardAnalysis[], labels: string[], series: number[] }> {
        const {data} = await DashboardRepository.getMarketplacePieData(filters);
        const parsedData = data.map((item: any) => DashboardAnalysesFactory.toDashboardAnalysis(item))
            .sort((a: IDashboardAnalysis, b: IDashboardAnalysis) =>
                (a.sumAmount.net < b.sumAmount.net) ? 1 : ((b.sumAmount.net < a.sumAmount.net) ? -1 : 0));
        const labels = parsedData.map((item: any) => item.key);
        const series = parsedData.map((item: any) => item.sumAmount.net);
        return {
            data: parsedData,
            labels,
            series,
        };
    }

    static async getMarketplaceDataLine(filters: IDashboardFilter) {
        const {data} = await DashboardRepository.getMarketplaceLineData(filters);
        let dataSeries = [];
        for (const [marketplace, values] of Object.entries(data.data)) {
            dataSeries.push({
                name: marketplace,
                // @ts-ignore
                data: values.map((item: number) => Math.round(item))
            })
        }
        return {
            labels: data.labels.sort(),
            series: dataSeries
        };
    }

    static async getSkuData(filters: IDashboardFilter)
        : Promise<{ data: IDashboardAnalysis[], labels: string[], series: number[] }> {
        const {data} = await DashboardRepository.getSkuData(filters);
        const parsedData = data.map((item: any) => DashboardAnalysesFactory.toDashboardAnalysis(item))
            .sort((a: IDashboardAnalysis, b: IDashboardAnalysis) =>
                (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
        const labels = parsedData.map((item: any) => item.key);
        const series = parsedData.map((item: any) => item.sumAmount.net);
        return {
            data: parsedData,
            labels,
            series,
        };
    }

    static async getFiltersData(): Promise<{ marketplacesList: IAnalysisMarketplace[] }> {
        const {data} = await AnalysisRepository.getFilters();
        return {
            marketplacesList: data.marketplaces,
        };
    }

    static async getTopProducts(filters: IDashboardFilter): Promise<{ [key: string]: IAnalysisTopProduct[] }> {
        const {data} = await DashboardRepository.getTopProducts(filters)

        Object.keys(data).forEach(marketplace => {
            data[marketplace] = data[marketplace].map((product: any) => AnalysisFactory.toTopProduct(product))
        })

        return data
    }

}
